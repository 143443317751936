import {createRouter, createWebHistory} from "vue-router";

import store from "../store/index";
import Login from "@/pages/login/Index";
import NoPermission from "@/pages/exception/NoPermission";
import NotFound from "@/pages/exception/NotFound";

const routes = [
    {
        path: '/',
        meta: {
            requireAuth: true,
        },
        children: [
            {
                name: 'home',
                path: "/",
                redirect: "/resource/search",
                // component: () => import("../pages/home/Index"),
                // meta: {
                //     title: "首页",
                //     requireAuth: false,
                //     key: "HomeIndex",
                // },
            },
            {
                name: 'apps',
                path: '/apps',
                meta: {
                    title: "应用",
                    requireAuth: false,
                    key: "apps",
                },
                children: [
                    {
                        name: 'search',
                        path: "/resource/search",
                        component: () => import("../pages/apps/resource/Search"),
                        meta: {
                            title: "搜索",
                            requireAuth: false,
                            key: "SearchIndex",
                        },
                    },
                    {
                        name: 'ResourceDemand',
                        path: "/resource/demand",
                        component: () => import("../pages/apps/resource/Demand"),
                        meta: {
                            title: "需求提交",
                            requireAuth: false,
                            key: "ResourceDemand",
                        },
                    },
                    {
                        name: 'ResourceCategory',
                        path: "/resource/category",
                        component: () => import("../pages/apps/resource/Category"),
                        meta: {
                            title: "资源分类",
                            requireAuth: false,
                            key: "ResourceCategory",
                        },
                    },
                    {
                        name: 'ResourceCategoryTwp',
                        path: "/resource/category/two",
                        component: () => import("../pages/apps/resource/CategoryTwo"),
                        meta: {
                            title: "资源二级分类",
                            requireAuth: false,
                            key: "ResourceCategoryTwo",
                        },
                    },
                    {
                        name: 'ResourceList',
                        path: "/resource",
                        component: () => import("../pages/apps/resource/List"),
                        meta: {
                            title: "搜索结果",
                            requireAuth: false,
                            key: "ResourceList",
                        }
                    }
                ],
            }

            // {
            //     name: "pages",
            //     path: "/pages",
            //     meta: {
            //         title: "页面",
            //         requireAuth: true,
            //         key: "pages",
            //     },
            //     children: [
            //         {
            //             name: 'work',
            //             path: "/work",
            //             component: () => import("../pages/home/Work"),
            //             meta: {
            //                 title: "工作台",
            //                 requireAuth: true,
            //                 key: "work",
            //             },
            //         }, {
            //             name: 'about',
            //             path: "/about",
            //             component: () => import("../pages/home/About"),
            //             meta: {
            //                 title: "关于",
            //                 requireAuth: true,
            //                 key: "about",
            //             },
            //         },
            //     ]
            // },
            // {
            //     name: "user",
            //     path: "/user",
            //     component: () => import("../pages/user/Index"),
            //     meta: {
            //         title: "个人中心",
            //         requireAuth: true,
            //         key: "user",
            //     },
            // },
            // {
            //     name: 'apps',
            //     path: '/apps',
            //     meta: {
            //         title: "应用",
            //         requireAuth: true,
            //         key: "apps",
            //     },
            //     children: [
            //         {
            //             name: "todo",
            //             path: "/apps/todo",
            //             redirect: "/apps/todo/home",
            //             meta: {
            //                 title: "待办",
            //                 requireAuth: true,
            //                 key: "appsTodo",
            //             },
            //             children: [
            //                 {
            //                     name: "appsTodoIndex",
            //                     path: "/apps/todo/home",
            //                     component: () => import("../pages/apps/todo/Index"),
            //                     meta: {
            //                         title: "待办",
            //                         requireAuth: true,
            //                         key: "appsTodoIndex",
            //                     },
            //                 },
            //                 {
            //                     name: "appsTodoList",
            //                     path: "/apps/todo/list",
            //                     component: () => import("../pages/apps/todo/List"),
            //                     meta: {
            //                         title: "待办清单",
            //                         requireAuth: true,
            //                         key: "appsTodoList",
            //                     },
            //                 },
            //                 {
            //                     name: "appsTodoItem",
            //                     path: "/apps/todo/item",
            //                     component: () => import("../pages/apps/todo/Item"),
            //                     meta: {
            //                         title: "待办详情",
            //                         requireAuth: true,
            //                         key: "appsTodoItem",
            //                     },
            //                 },
            //                 {
            //                     name: "appsTodoAdd",
            //                     path: "/apps/todo/add",
            //                     component: () => import("../pages/apps/todo/Add"),
            //                     meta: {
            //                         title: "添加待办",
            //                         requireAuth: true,
            //                         key: "appsTodoAdd",
            //                     },
            //                 },
            //             ],
            //         },
            //         {
            //             name: "appsJike",
            //             path: "/apps/jike",
            //             redirect: "/apps/jike/home",
            //             meta: {
            //                 title: "集客",
            //                 requireAuth: true,
            //                 key: "appsJike",
            //             },
            //             children: [
            //                 {
            //                     name: "appsJikeIndex",
            //                     path: "/apps/jike/home",
            //                     component: () => import("../pages/apps/jike/Index"),
            //                     meta: {
            //                         title: "集客首页",
            //                         requireAuth: true,
            //                         key: "appsJikeIndex",
            //                     },
            //                 },
            //                 {
            //                     name: "appsJikeOrder",
            //                     path: "/apps/jike/order",
            //                     component: () => import("../pages/apps/jike/Order"),
            //                     meta: {
            //                         title: "集客工单",
            //                         requireAuth: true,
            //                         key: "appsJikeOrder",
            //                     },
            //                 },
            //             ],
            //         },
            //     ],
            // },
            // {
            //     name: 'test',
            //     path: "test",
            //     component: () => import("../pages/home/Test"),
            //     meta: {
            //         title: "测试",
            //         requireAuth: true,
            //         key: "test",
            //     },
            // },
        ],
    },
    {
        name: 'login',
        path: "/login",
        component: Login,
        meta: {
            title: "登陆",
            requireAuth: false,
        },
    },
    {
        path: "/exception",
        redirect: "/404",
        children: [
            {
                path: "/404",
                component: NotFound,
                meta: {
                    title: "404",
                    requireAuth: false,
                },
            },
            {
                path: "/403",
                component: NoPermission,
                meta: {
                    title: "403",
                    requireAuth: false,
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = (to?.meta?.title) || document.title;


    //路由不匹配，跳转404
    if (to.matched.length === 0) {
        next({
            path: "/404",
        });
    }

    if (to.meta.requireAuth) {
        // 对路由进行验证
        if (store.state.authorization) {
            // 已经登陆
            next(); // 正常跳转到你设置好的页面
        } else {
            // 未登录则跳转到登陆界面
            next({
                path: "/login",
                query: {redirect: to.fullPath},
            });
        }
    } else {
        //如果是登陆重定向，带上query
        if (from.path === "/login" && JSON.stringify(from.query) !== "{}") {
            to.query = from.query;
        }
        next();
    }

});

export default router;
