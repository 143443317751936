<template>
  <router-view />
</template>
<script>

import upgrade from "@/api/config/upgrade";
export default {
  data() {
    return {
      pc: false,
      screenWidth: window.innerWidth,
      localVersion: localStorage.getItem('localVersion') || '', // 从localStorage获取本地版本
    }
  },
  components: {
  },
  mounted() {

    this.getUpgradeRecord();
  },
  methods: {
    getUpgradeRecord(){
      upgrade.getUpgradeRecord().then((res) => {
        if (res.status === 200) {
          let  newVersion = res.data[0]
          if (this.localVersion !== newVersion.version) {
            this.localVersion = newVersion.version; // 将服务器版本保存到本地版本
            localStorage.setItem('localVersion', this.localVersion); // 将本地版本保存到localStorage
            console.log("更新版本："+this.localVersion)
          }
        }
      });
    },
  }
};
</script>

<style>
body{
  margin: 0;
  padding: 0;
  background-color: #F6F7FB;
}
.page {
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: auto;
}
.pc-qrcode{
  margin: 100px auto 20px;
  text-align: center;
}
.pc-tips{
  font-size: 24px;
  text-align: center;
}
.article-page{
  padding: 10px;
}
a{
  text-decoration: none;
  color: inherit;
  display: block;
}

a:link,a:visited, a:hover,a:active{
  color: inherit;
}
</style>
