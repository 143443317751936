export default {
    login(state, data) {
        state.user = data;
        localStorage.setItem("user", JSON.stringify(data));
    },
    changeAuthorization(state, authorization) {
        state.authorization = authorization;
        localStorage.setItem("authorization", authorization);
    },
};
