import tool from "../utils/tool";

export default {

    baseURL:"https://api.innotrea.com/",

    user: tool.isJsonString(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user"))
        : "",
    authorization: localStorage.getItem("authorization")
        ? localStorage.getItem("authorization")
        : "",
};
