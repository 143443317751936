<template>
<div class="page">

</div>
</template>
<script>


export default {
  name: "LoginIndex",
}

</script>

<style scoped>
</style>