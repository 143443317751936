import request from "../../utils/request";

export default {
    getUpgradeRecord(params) {
        return request({
            url: "/config/upgrade/record",
            method: "get",
            data:params
        });
    },
};
