import axios from "axios";
import qs from "qs";
import state from "../store/state";
import store from "../store/index";
import router from "@/router";

const service = axios.create({
    baseURL: state.baseURL,
    timeout: 10000, // 请求超时时间
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
});

// request 拦截器
service.interceptors.request.use(
    (config) => {
        config.headers.Authorization = state.authorization;
        // 这里可以自定义一些config 配置
        config.data = qs.stringify(config.data); // 转为formdata数据格式
        //判断get
        if (config.method === "get" && config.data) {
            config.url = config.url + "?" + config.data;
        }
        return config;
    },
    (error) => {
        //  这里处理一些请求出错的情况
        Promise.reject(error);
    }
);

// response 拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data;

        //如果存在authorization就更新
        if (response.headers.authorization !== undefined) {
            store.commit("changeAuthorization", response.headers.authorization);
        }
        //如果返回code是403xx,跳转403页面
        if (res.code >= 40300 && res.code < 40400) {
            router.push({
                path: "/403",
                query: {redirect: router.currentRoute.value.fullPath},
            });
        }
        //如果返回code是404xx,跳转404页面
        if (res.code >= 40400 && res.code < 40500) {
            router.push({
                path: "/404",
                query: {redirect: router.currentRoute.value.fullPath},
            });
        }
        // 这里处理一些response 正常放回时的逻辑
        return res;
    },
    (error) => {
        // 这里处理一些response 出错时的逻辑
        return Promise.reject(error);
    }
);

export default service;
