import { createApp } from 'vue'
import TDesign from 'tdesign-mobile-vue';
import router from '@/router';
import App from './App.vue'
import axios from "axios";
// 全局注册微信jsSDK


// 引入组件库的少量全局样式变量
import 'tdesign-mobile-vue/es/style/index.css';
const app = createApp(App);
app.use(router);
app.use(TDesign);

app.config.globalProperties.$axios = axios;


app.mount('#app');


