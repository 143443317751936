<template>


  <div class="image">
    <t-image
        fit="cover"
        :src="imageSrc"
    ></t-image>
  </div>
  <div class="info">
    404
  </div>

  <div class="back-home-bottom">
    <t-button size="large" theme="primary" block @click="backHome">返回首页</t-button>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "NotFound",
  data(){
    return{
      imageSrc: "/logo.png"
    }
  },
  methods: {
    backHome() {
      router.push({path: '/'})
    }
  }
}
</script>

<style scoped>
.image{
  width: 60vw;
  margin: 20vh auto 2vh;
}
.info{
  text-align: center;
  font-size: 24px;
  font-weight: 200;
}

.back-home-bottom{
  width: 80vw;
  margin: 20vw auto;
}
</style>